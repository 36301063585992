<template>
  <section style="margin: 0 24px">
    <nav v-if="!$route.params.id" class="tabs-navbar" :class="{ dark: false }">
      <ul class="tabs-navbar-list">
        <!-- v-can="item.permission" -->
        <div v-for="(item, idx) in pageList" :key="idx">
          <li
            v-if="$route.query.slug === item.slug"
            class="tabs-navbar-list__item"
          >
            <router-link
              class="tabs-navbar-list__item-link"
              :to="{
                path: `/settings-category/${item.link}`,
                query: { slug: $route.query.slug },
              }"
            >
              {{ $t(item.name) }}
            </router-link>
          </li>
        </div>
      </ul>
    </nav>

    <router-view />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeName: "",
      pageList: [
        // {
        //   name: "message.currency",
        //   link: "currenciesWarehouses/index",
        //   permission: "currencies.index",
        //   slug: "warehouse",
        // },
        {
          name: "message.systems",
          link: "systems/index",
          permission: "systems.index",
          slug: "system-settings"
        },
        {
          link: "users/index",
          name: "message.users",
          permission: "users.index",
          slug: "actions",
        },
        {
          link: "roles/index",
          name: "message.roles",
          permission: "roles.index",
          slug: "actions",
        },
        {
          link: "permissions/index",
          name: "message.access_rights",
          permission: "permissions.index",
          slug: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      role: "role",
      // mode: "MODE"
    }),
  },

  mounted() {
    this.activeName = this.$route.name;
  },
  methods: {
    handleClickTabs(tab) {
      this.activeName = tab.name;
    },
  },
};
</script>

<style lang="scss"></style>
